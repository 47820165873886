<template>
  <div>
    <TablePage>
      <template #toolbar>
        <span>{{ $t("scene.version") }}:&nbsp;</span>{{ version }}
      </template>
      <el-form
        ref="sceneForm"
        :model="sceneForm"
        inline
        label-width="120px"
        :rules="sceneFormRules"
      >
        <el-form-item
          :label="$t('scene.zip')"
          prop="file"
        >
          <el-upload
            ref="sceneUpload"
            class="customizeUpload"
            :headers="{Token:token}"
            :auto-upload="false"
            :on-change="sceneChange"
            name="se_file"
            :data="{server_list:sceneForm.server_id}"
            :action="`${url}se_api/se`"
            :on-success="successUpload"
            accept=".zip"
            :limit="2"
          >
            <el-button>
              {{ $t('scene.click2Upload') }}
            </el-button>
          </el-upload>
        </el-form-item>
        <el-form-item
          :label="$t('log.server')"
          prop="server_id"
        >
          <div class="serveRemind">
            <el-select
              v-model="sceneForm.server_id"
              :popper-append-to-body="false"
              filterable
              multiple
            >
              <el-option
                v-for="item in serverOptions"
                :key="item.id"
                :value="item.id"
                :label="item.server_ip"
              />
            </el-select>
            <span class="remindText">{{ $t('scene.remindText') }}</span>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="confirmUpload"
          >
            {{ $t("common.confirm") }}
          </el-button>
        </el-form-item>
      </el-form>
    </TablePage>
  </div>
</template>

<script>
import { push_profiles_version } from '@/api/scene.js'
import { get_server_list_select } from '@/api/config'
import TablePage from '@/components/TablePage.vue'
import { customConfig } from '@/api/request'
import { zh } from '@/api/code.js'
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: {
    TablePage
  },
  data () {
    return {
      version: '',
      serverOptions: [],
      sceneForm: {
        server_id: '',
        file: ''
      },
      sceneFormRules: {
        file: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }]
      },
      url: customConfig.baseURL
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  created () {
    this.init()
  },
  methods: {
    ...mapMutations(['addToken', 'addSAID']),
    // 获取场景版本号
    async getVersion () {
      const res = await push_profiles_version()
      this.version = res.data.data
    },
    // 修改表单信息，完成校验
    sceneChange (file, fileList) {
      console.log(file, fileList)
      const len = fileList.length
      if (len > 1) {
        fileList.splice(0, 1)
      }
      if (file) {
        this.sceneForm.file = file
      }
    },
    // 上传成功回掉
    successUpload (res) {
      this.uploadSceneLoading.close()
      if (res.code === 1002) {
        this.addToken('')
        this.addSAID('')
        this.$router.push('/login')
        return
      }
      if (localStorage.lang == 'en') {
        if (res.code === 0) {
          this.$message.success(this.$t('common.uploadSuc'))
        } else {
          this.$message.error(res.msg)
        }
      } else {
        if (res.code === 0) {
          this.$message.success(this.$t('common.uploadSuc'))
        } else {
          this.$message.error(zh[res.code])
        }
      }
    },
    // 获取服务器列表
    async getServerListSelect () {
      const res = await get_server_list_select()
      if (res.data.code === 0) {
        this.serverOptions = res.data.data
      }
    },
    // 确认上传
    confirmUpload () {
      this.$refs.sceneForm.validate((valid) => {
        if (!valid) return
        this.uploadSceneLoading = this.$globalLoading()
        this.$refs.sceneUpload.submit()
      })
    },
    init () {
      this.getVersion()
      this.getServerListSelect()
    }
  }
}
</script>

<style lang='scss' scoped>
.customizeUpload {
  display: flex;
}
.serveRemind {
  display: flex;
  flex-direction: column;
  .remindText {
    font-size: 12px;
    color: #999;
  }
}
</style>
