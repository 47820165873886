/**
 * @description: 场景管理接口列表
 */

import request from './request'

// 获取场景版本
export function push_profiles_version () {
  return request({
    url: '/se_api/se_version',
    method: 'get'
  })
}
